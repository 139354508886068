import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


import Tools from './components/Tools';
import Login from './components/AccountComponents/Login'; 
import Register from './components/AccountComponents/Register'; 
import ForgotPassword from './components/AccountComponents/ForgotPassword';
import UpdatePassword from './components/AccountComponents/UpdatePassword';

import Navigation from './components/Navigation';

import Home from './components/Home';
import Interview from './components/ProjectComponents/Interview';


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { NavigationProvider } from './utilities/NavigationContext';
import { AuthProvider } from './utilities/AuthContext';
import { SharedFunctionProvider } from './utilities/SharedFunctionContext';

import PrivateRoutes from './utilities/PrivateRoutes';

// Projects
import Explore from './components/ProjectComponents/Explore';
import LocalAI from './components/ProjectComponents/LocalAI';
import InterviewProject from './components/ProjectComponents/InterviewProject';
import ClipCopy from './components/ProjectComponents/ClipCopy';
import RealTime from './components/ProjectComponents/RealTime';

//Analytics
import Log from './components/AnalyticComponents/Log';
import AnalyticsDashboard from './components/AnalyticComponents/AnalyticDashboard';

// Community
import Discussion from './components/CommunityComponents/Discussion';
import Community from './components/MainComponents/Community';

// Blog
import BlogList from "./components/MainComponents/Blog";
import CreateBlog from "./components/BlogComponents/CreateBlog";
import BlogPost from "./components/BlogComponents/BlogPost";
import EditPost from "./components/BlogComponents/EditPost";

// Education
import Education from './components/MainComponents/Education';


// Transaction
import Usage from './components/AccountComponents/Usage';
import Pricing from './components/TransactionComponents/Pricing';
import SuccessfulPayment from './components/TransactionComponents/SuccessPayment';
import FailedPayment from './components/TransactionComponents/FailedPayment';
import Transaction from './components/AccountComponents/Transaction';

// rewrite 
import Master from './components/Master';

class App extends React.Component {

  render() {

    return (
      <Router>
        <AuthProvider>
        <NavigationProvider>
        <SharedFunctionProvider>
            <ToastContainer />
            <Navigation />

            <Routes>
             
              <Route path="/" element={<Home />} />
              <Route element={<PrivateRoutes />}>

                  <Route path="/tools" element={<Tools />} />
                  <Route path="/master" element={<Master />} />

                  <Route path="/updatepassword" element={<UpdatePassword />} />

              
                  {/* Admin */}
                  <Route path="/usage" element={<Usage />} />
                  <Route path="/log" element={<Log />} />
                  <Route path="/analytics" element={<AnalyticsDashboard />} />

        
              </Route>       
                 
              {/* Blogs */}
              <Route path="/blog-list" element={<BlogList />} />
              <Route path="/create-blog" element={<CreateBlog />} />
              <Route path="/blog" element={<BlogPost />} />
              <Route path="/edit-post" element={<EditPost />} />

              {/* Education */}
              <Route path="/education" element={<Education />} />

              {/* User pathway*/}
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgotpassword" element={<ForgotPassword />} />

              {/* Projects */}
              <Route path="/realtime" element={<RealTime />} />
              <Route path="/localai" element={<LocalAI />} />
              <Route path="/explore" element={<Explore />} />

              <Route path="/clipcopy" element={<ClipCopy />} />
              <Route path="/interview" element={<Interview />} />
              <Route path="/project" element={<InterviewProject />} />

              {/* Community Discussion*/}
              <Route path="/community" element={<Community />} />
              <Route path="/discussion" element={<Discussion />} />

              {/* Transaction */}
              <Route path="/transaction" element={<Transaction />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/payment-success" element={<SuccessfulPayment />} />
              <Route path="/payment-failed" element={<FailedPayment />} />
              
      

            </Routes>
        </SharedFunctionProvider>
        </NavigationProvider>
        </AuthProvider>
      </Router>
    );
  }
}

export default App;
