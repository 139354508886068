import React, { Component } from "react";
import axios from "axios";

const EndPoint = process.env.REACT_APP_API_ENDPOINT;

class ChatApp extends Component {
  constructor(props) {
    super(props);
    this.dataChannel = null;
  }

  async init() {
    try {
      // Get an ephemeral key from your server
      const tokenResponse = await axios.get(`${EndPoint}/chat/session`);
      const EPHEMERAL_KEY = tokenResponse.data.client_secret.value;

      // Create a peer connection
      const pc = new RTCPeerConnection();

      // Set up to play remote audio from the model
      const audioEl = document.createElement("audio");
      audioEl.autoplay = true;
      pc.ontrack = (e) => {
        audioEl.srcObject = e.streams[0];
      };

      // Add local audio track for microphone input in the browser
      const ms = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      pc.addTrack(ms.getTracks()[0]);

      // Set up data channel for sending and receiving events
      const dc = pc.createDataChannel("oai-events");
      dc.addEventListener("message", (e) => {
        const realtimeEvent = JSON.parse(e.data);
        console.log("Realtime Event from AI:", realtimeEvent);

        // Example handling of AI response
        if (realtimeEvent.type === "response.create") {
          console.log("AI Response:", realtimeEvent.response);
        }
      });

      // Start the session using the Session Description Protocol (SDP)
      const offer = await pc.createOffer();
      await pc.setLocalDescription(offer);

      const baseUrl = "https://api.openai.com/v1/realtime";
      const model = "gpt-4o-realtime-preview-2024-12-17";
      const sdpResponse = await axios.post(`${baseUrl}?model=${model}`, offer.sdp, {
        headers: {
          Authorization: `Bearer ${EPHEMERAL_KEY}`,
          "Content-Type": "application/sdp",
        },
      });

      const answer = {
        type: "answer",
        sdp: sdpResponse.data,
      };
      await pc.setRemoteDescription(answer);

      // Send an example client event
      const responseCreate = {
        type: "response.create",
        response: {
          modalities: ["text"],
          instructions: "Write a haiku about code",
        },
      };
      dc.send(JSON.stringify(responseCreate));

      return dc; // Return the data channel for sending messages
    } catch (error) {
      console.error("Error initializing real-time communication:", error);
    }
  }

  render() {
    return (
      <div className="flex justify-center items-center h-screen">
        <button
          className="px-6 py-3 bg-blue-500 text-white font-bold rounded hover:bg-blue-600"
          onClick={() => this.init()}
        >
          Start
        </button>
      </div>
    );
  }
}

export default ChatApp;
